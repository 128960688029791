#identification-modal {
    width: 600px!important;
    max-width: 600px!important;
    margin: 8% auto;
    padding: 35px;
    @media #{$md-layout} {
      width: 600px!important;
      max-width: 600px!important;
    }
    @media #{$xs-layout} {
      width: 100%!important;
      max-width: 100%!important;
      padding: 35px 0;
    }
    .modal-header {
      .close {
        cursor: pointer;
  
        opacity: 1;
        color: #333;
        &:hover {
          color: $theme-color;
        }
      }
    }
    .modal-body {
      padding: 35px 15px;
    }
    .quickview-big-img {
      img {
        width: 100%;
      }
    }
  }