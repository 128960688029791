.gdpr-settings-panel{
    $gdpr-mobile-size:800px;
    $gdpr-active-color1:#00C0D5;
    $gdpr-active-color2:#0092a2;

    $gdpr-inactive-color1:#CCCCCC;
    $gdpr-inactive-color2:#FFFFFF;
    @import '../../../node_modules/react-gdpr/sass/GDPRSettings.scss';

    .gdpr-title{
        font-family: "futura-medium",Arial,sans-serif;
    }
    
    
    .gdpr-description{
        font-family: "raleway-regular",Arial,sans-serif;
    }

    .gdpr-status{
        font-family: "futura-medium",Arial,sans-serif;
    }

    .gdpr-button{
        font-family: "futura-medium",Arial,sans-serif;
        border-radius: 0!important;
    }

    .gdpr-settings {
        position: absolute;
        .os-host-flexbox {
            .os-padding {
                .os-viewport{
                    .os-content {
                        display: block;
                    }
                }
            }
        }

        .gdpr-overlay{
            display: none;
        }
        .gdpr-container{
            top: 0px;
        }
        .gdpr-popin{
            position: inherit;
            margin:auto;
            top: auto;
            left: auto;
            transform: none;
            margin-left: -15px;
            width: 100%;
            height: 700px;
        }

    }
}
