/* 
Couleur frais péi
*/
.text-orange{
    color : #ff5722  !important;
}
/*
Background frais péi
*/
.bg-add-cart{
    background-color : #2E2E2E !important;
    color : white !important;
}
.bg-add-cart:hover{
    background-color : #00C851 !important;
    color : white !important;
}

.h-100{
    height: 100% !important;
}

.w-100{
    width : 100% !important
}
.cart-custom:hover {
    position: absolute;
    z-index: -1;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: "aze";
    transition: all 0.5s cubic-bezier(0.645, 0.045, 0.355, 1)
}

.product-wrap .product-img-action, .product-list-image-wrap .product-img-action
{
    position: absolute;
    top : 12px;
    left : 12px;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
    -moz-appearance: textfield;
  }

  
input[type="number"]:focus, input[type="number"]:hover{
   border: 0px;
   box-shadow: none;
  }